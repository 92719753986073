@layer modules, ui, base;
@layer ui {
  /* @define LayoutColumn */

.layouColumn_jds-LayoutColumn__QufQy {
  box-sizing: border-box;
  width: 100%;
  /*
  "min-width: 0" is used to prevent the carousel component to overflow and give a too big size at the layout column
  We cannod use a simple "overflow-x: hidden" on the layout cause we need to display the arrows which have a light overflow on the layout column
  */
  min-width: 0;
  margin: var(--spacer-100) var(--Layout-column-margin-size);
}

.layouColumn_jds-LayoutColumn__QufQy:first-child {
  margin-left: var(--Layout-column-margin-size);
}

.layouColumn_jds-LayoutColumn__QufQy:last-child {
  margin-right: var(--Layout-column-margin-size);
}

@media (min-width: 48rem) {
  .layouColumn_jds-LayoutColumn__QufQy {
    flex: 1;
    margin: 0;
  }

  .layouColumn_jds-LayoutColumn--fixedWidth__JCI_8 {
    --LayoutColumn-FixedWidth: calc(var(--Layout-column-size) * var(--layout-column-number) + ( var(--layout-column-number) - 1 ) * var(--Layout-column-gutter-size));
    flex: 0 0 var(--LayoutColumn-FixedWidth);
  }
}

}
@layer ui {
  /** @define Layout */

.layout_jds-Layout__hFF0F {
  --Layout-columnsBase: 12;
  --Layout-size: 100%;
  --Layout-padding: calc((100% - var(--Layout-size)) / 2);
  --Layout-column-gutter-size: var(--spacer-200);
  --Layout-column-margin-size: 1.25rem; /* 20px (specific spacer for Layout) */
  --Layout-column-size: calc(( ( var(--Layout-size) - var(--Layout-column-margin-size) * 2 ) - var(--Layout-column-gutter-size) * ( var(--Layout-columnsBase) - 1) ) / var(--Layout-columnsBase));

  display: flex;
  box-sizing: content-box; /* Fix box sizing to override some style coming from legacy component */
  flex-wrap: wrap;
  width: var(--Layout-size);
  padding: 0 var(--Layout-padding);
}

.layout_jds-Layout--isBo__hWLcI {
  --Layout-size: calc(100% - var(--jds-bo-width-delta, 0px));
}

.layout_jds-Layout--isFullWidth__Lkbb3 {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
}

.layout_jds-Layout--topPadding__vMye5 {
  padding-top: var(--spacer-400);
}

.layout_jds-Layout--bottomPadding__sa1oV {
  padding-bottom: var(--spacer-400);
}

.layout_jds-Layout--greyBlock__k6wDa {
  background: var(--color-grey--025);
}

.layout_jds-Layout--blackBlock__kQo1N {
  background: var(--color-black);
  color: var(--color-white);
}

.layout_jds-Layout--whiteBlock__KxtWG {
  background: var(--color-white);
}

.layout_jds-Layout--separator__6Jcn_ {
  border-bottom: 1px solid var(--color-grey--200);
}

@media (min-width: 48rem) {
  .layout_jds-Layout__hFF0F {
    --Layout-size: var(--medium-breakpoint);
    --Layout-column-gutter-size: var(--spacer-300);

    flex-wrap: initial;
    gap: var(--Layout-column-gutter-size);
  }

  .layout_jds-Layout--isBo__hWLcI {
    --Layout-size: calc(var(--medium-breakpoint) - var(--jds-bo-width-delta, 0px));
  }

  .layout_jds-Layout--isFullWidth__Lkbb3 {
    --Layout-column-margin-size: var(--spacer-400);
  }

  .layout_jds-Layout--topPadding__vMye5:not(.layout_jds-Layout--isBo__hWLcI) {
    padding-top: var(--spacer-600);
  }

  .layout_jds-Layout--bottomPadding__sa1oV:not(.layout_jds-Layout--isBo__hWLcI) {
    padding-bottom: var(--spacer-600);
  }
}

@media (min-width: 64rem) {
  .layout_jds-Layout__hFF0F {
    --Layout-size: var(--large-breakpoint);
    --Layout-column-gutter-size: var(--spacer-300);
    --Layout-column-margin-size: var(--spacer-300);
  }

  .layout_jds-Layout--isBo__hWLcI {
    --Layout-size: calc(var(--large-breakpoint) - var(--jds-bo-width-delta, 0px));
  }

  .layout_jds-Layout--isFullWidth__Lkbb3 {
    --Layout-column-margin-size: var(--spacer-400);
  }

  .layout_jds-Layout--topPadding__vMye5:not(.layout_jds-Layout--isBo__hWLcI) {
    padding-top: var(--spacer-800);
  }

  .layout_jds-Layout--bottomPadding__sa1oV:not(.layout_jds-Layout--isBo__hWLcI) {
    padding-bottom: var(--spacer-800);
  }

  .layout_jds-Layout--isSmall__03qdy {
    --Layout-paddingIsSmall: calc(( var(--Layout-column-size) + var(--Layout-column-gutter-size) ) * 2);

    width: calc(var(--Layout-size) - var(--Layout-paddingIsSmall) * 2);
    padding-right: calc(var(--Layout-padding) + var(--Layout-paddingIsSmall));
    padding-left: calc(var(--Layout-padding) + var(--Layout-paddingIsSmall));
  }

  .layout_jds-Layout--isExtraSmall__Hf5k8 {
    --Layout-paddingIsExtraSmall: calc(( var(--Layout-column-size) + var(--Layout-column-gutter-size) ) * 3);

    width: calc(var(--Layout-size) - var(--Layout-paddingIsExtraSmall) * 2);
    padding-right: calc(var(--Layout-padding) + var(--Layout-paddingIsExtraSmall));
    padding-left: calc(var(--Layout-padding) + var(--Layout-paddingIsExtraSmall));
  }
}

@media (min-width: 80rem) {
  .layout_jds-Layout__hFF0F {
    --Layout-size: var(--major-breakpoint);
    --Layout-column-gutter-size: var(--spacer-400);
  }

  .layout_jds-Layout--isBo__hWLcI {
    --Layout-size: calc(var(--major-breakpoint) - var(--jds-bo-width-delta, 0px));
  }

  .layout_jds-Layout--isFullWidth__Lkbb3 {
    --Layout-column-margin-size: var(--spacer-500);
  }
}

@media (min-width: 90rem) {
  .layout_jds-Layout--isFullWidth__Lkbb3 {
    --Layout-size: var(--huge-breakpoint);
  }

  /* stylelint-disable-next-line plugin/selector-bem-pattern */
  .layout_jds-Layout--isFullWidth__Lkbb3.layout_jds-Layout--isBo__hWLcI {
    --Layout-size: calc(var(--huge-breakpoint) - var(--jds-bo-width-delta, 0px));
  }
}

@media (min-width: 120rem) {
  .layout_jds-Layout--isFullWidth__Lkbb3 {
    --Layout-size: var(--extra-breakpoint);

    max-width: var(--Layout-size);
    padding-right: var(--Layout-padding);
    padding-left: var(--Layout-padding);
  }

  /* stylelint-disable-next-line plugin/selector-bem-pattern */
  .layout_jds-Layout--isFullWidth__Lkbb3.layout_jds-Layout--isBo__hWLcI {
    --Layout-size: calc(var(--extra-breakpoint) - var(--jds-bo-width-delta, 0px));
  }
}

}
@layer ui {
  /*  @define CardTitle */
/* stylelint-disable plugin/selector-bem-pattern */
.cardTitle_jds-CardTitle__link__joX8c,
.cardTitle_jds-CardTitle__link__joX8c:visited,
.cardTitle_jds-CardTitle__link__joX8c:hover,
.cardTitle_jds-CardTitle__link__joX8c:active,
.cardTitle_jds-CardTitle__link__joX8c:focus,
[class*="jds-Link"].cardTitle_jds-CardTitle__link__joX8c,
[class*="jds-Link"].cardTitle_jds-CardTitle__link__joX8c:visited,
[class*="jds-Link"].cardTitle_jds-CardTitle__link__joX8c:hover,
[class*="jds-Link"].cardTitle_jds-CardTitle__link__joX8c:active,
[class*="jds-Link"].cardTitle_jds-CardTitle__link__joX8c:focus {
  outline: 0;
  text-decoration: none;
}

.cardTitle_jds-CardTitle__link__joX8c {
  font-weight: var(--bold);
}

.cardTitle_jds-CardTitle__link__joX8c::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: thin solid var(--color-grey--200);
  border-radius: var(--Card-radius);
  box-shadow: none;
}

.cardTitle_jds-CardTitle__link__joX8c:hover::after {
  border-color: var(--color-grey--300);
  box-shadow: var(--hover-shadow);
}

.cardTitle_jds-CardTitle__link__joX8c:active::after {
  border-color: var(--color-grey--400);
}

.cardTitle_jds-CardTitle__link__joX8c:focus::after {
  --CardBorder: 2px;
  border-color: var(--color-grey--200);
  outline: var(--CardBorder) solid var(--color-grey--400);
  outline-offset: var(--CardBorder);
}

@media (min-width: 64rem) {
  .cardTitle_jds-CardTitle--big__tFhdl {
    font-size: var(--heading);
    line-height: var(--headingLineHeight);
  }
}

}
@layer ui {
  /* @define CardImage */

.cardImage_jds-CardImage__I_GO2 {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
  aspect-ratio: 16 / 9;
}

.cardImage_jds-CardImage__I_GO2::before {
  --CardImage-blurSize: 8px;

  content: "";
  position: absolute;
  z-index: 0;
  top: calc(var(--CardImage-blurSize) * -1);
  right: calc(var(--CardImage-blurSize) * -1);
  bottom: calc(var(--CardImage-blurSize) * -1);
  left: calc(var(--CardImage-blurSize) * -1);
  /* "--backgroundImageUrl" variable is defined at the html level (ex: <header style={{"--backgroundImageUrl": `url(url)`}}>) */
  background-image: var(--backgroundImageUrl);
  background-size: contain;
  filter: blur(var(--CardImage-blurSize)) brightness(75%);
}

.cardImage_jds-CardImage__image__jS8zd {
  position: absolute;
  top: 50%;
  width: 100%;
  height: auto;
  transform: translateY(-50%);
}

}
@layer ui {
  /* @define CardTagsListing */

.cardTagListing_jds-CardTagsListing__O6NOO {
  display: inline;
  margin: 0;
  padding: 0;
  list-style: none;
}

.cardTagListing_jds-CardTagsListing__item__WHxmt {
  display: inline-block;
  margin: 0 var(--spacer-100) var(--spacer-50) 0;
}

}
@layer ui {
  /* @define CardTime */

.cardTime_jds-CardTime__5StAm {
  display: block;
  margin-top: var(--spacer-200);
  color: var(--color-grey--700);
}

/* stylelint-disable-next-line plugin/selector-bem-pattern */
[class*="jds-Card--isDark"] .cardTime_jds-CardTime__5StAm {
  color: var(--color-grey--200);
}

@media (min-width: 48rem) {
  .cardTime_jds-CardTime__5StAm:not(.cardTime_jds-CardTime--isPush__yMi7n) {
    flex: 1 auto;
    align-self: flex-end;
    text-align: right;
  }
}

@media (min-width: 64rem) {
  .cardTime_jds-CardTime__5StAm:not(.cardTime_jds-CardTime--isPush__yMi7n) {
    flex: 1 0 auto;
  }
}

}
@layer ui {
  /* @define Listing */

.cardListing_jds-Listing__6rNno {
  display: block;
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.cardListing_jds-Listing__Item__nw0fv {
  display: block;
}

.cardListing_jds-Listing__6rNno:not(.cardListing_jds-Listing--removeSpacing__fD5lO) .cardListing_jds-Listing__Item__nw0fv:not(:first-child) {
  margin-top: var(--spacer-200);
}

@media (min-width: 48rem) {
  .cardListing_jds-Listing__6rNno:not(.cardListing_jds-Listing--isPush__XYDKK) {
    display: block;
  }

  .cardListing_jds-Listing__6rNno:not(.cardListing_jds-Listing--isPush__XYDKK) .cardListing_jds-Listing__Item__nw0fv {
    display: inline;
    margin-bottom: 0;
  }

  .cardListing_jds-Listing__6rNno:not(.cardListing_jds-Listing--isPush__XYDKK) .cardListing_jds-Listing__Item__nw0fv > * {
    vertical-align: middle;
  }

  /* stylelint-disable-next-line plugin/selector-bem-pattern */
  .cardListing_jds-Listing__6rNno:not(.cardListing_jds-Listing--isPush__XYDKK) .cardListing_jds-Listing__Item__nw0fv:not(:last-child)::after {
    content: "";
    display: inline-block;
    width: 1px;
    height: 12px;
    margin: 0 var(--spacer-100);
    background: var(--color-grey--500);
    vertical-align: middle;
  }
}

}
@layer ui {
  /* @define CardSpaceBetween */

.cardSpacebetween_jds-CardSpaceBetween__6e4V3 {
  align-items: flex-start;
}

.cardSpacebetween_jds-CardSpaceBetween__6e4V3:not(.cardSpacebetween_jds-CardSpaceBetween--removeSpacing__OQDQz) {
  margin-top: var(--spacer-200);
}

/* stylelint-disable-next-line plugin/selector-bem-pattern */
.cardSpacebetween_jds-CardSpaceBetween__6e4V3 > *:not(:first-child) {
  margin-top: var(--spacer-200);
}

.cardSpacebetween_jds-CardSpaceBetween--hasDivider__mapyk {
  padding-top: var(--spacer-200);
  border-top: var(--border);
}

@media (min-width: 48rem) {
  .cardSpacebetween_jds-CardSpaceBetween__6e4V3:not(.cardSpacebetween_jds-CardSpaceBetween--isPush__TJ16i) {
    display: flex;
    justify-content: space-between;
    gap: var(--spacer-200);
  }

  /* stylelint-disable plugin/selector-bem-pattern */
  .cardSpacebetween_jds-CardSpaceBetween__6e4V3:not(.cardSpacebetween_jds-CardSpaceBetween--isPush__TJ16i) > *,
  .cardSpacebetween_jds-CardSpaceBetween__6e4V3:not(.cardSpacebetween_jds-CardSpaceBetween--isPush__TJ16i) > *:not(:first-child) {
    margin-top: 0;
  }
  /* stylelint-enable plugin/selector-bem-pattern */
}

}
@layer ui {
  /* @define CardFeedback */

.cardFeedback_jds-CardFeedback__Lr8jw {
  margin-top: var(--spacer-200);
  color: var(--mainColor);
  font-weight: var(--bold);
}

.cardFeedback_jds-CardFeedback__Text__aoaF2 {
  margin-right: var(--spacer-100);
  color: var(--color-black);
  vertical-align: middle;
}

}
@layer base {
  .Network_container__yLIzl a[class*="jds-Pagination__activeButton"] {
  background: var(--color-black) !important;
  color: var(--color-white) !important;
}

.Network_filters__DFLfT {
  position: relative;
  z-index: var(--z-above-ui-kit-cards); /* to override some z-index inside Cards, below the FilterBar */
}

.Network_grid__TrWY3 {
  display: grid;
  grid-auto-rows: minmax(100px, auto);
  gap: var(--spacer-200);
  grid-template-columns: repeat(1, 1fr);
  margin-top: var(--spacer-400);
}

.Network_image__ch_cp {
  border-radius: var(--radius-200);
  -o-object-fit: contain;
     object-fit: contain;
}

@media (min-width: 64rem) {
  .Network_grid__TrWY3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 90rem) {
  .Network_grid__TrWY3 {
    grid-template-columns: repeat(4, 1fr);
  }
}

.Network_grid__withError__4IZ8a {
  grid-template-columns: none;
}

}
@layer base {
  .LanguageDropdown_triggerButton__ot69F {
  border-color: transparent;
  outline: 1px solid var(--color-grey--200);
  background: var(--color-white);
  color: var(--color-grey--600);
}

}
@layer base {
  .ActionBar_column__tAwEt {
  margin-top: 0;
  margin-bottom: 0;
}

.ActionBar_actionsContainer__33tF1 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: var(--spacer-300) 0;
  gap: var(--spacer-200);
}

.ActionBar_buttonsContainer__A2jG_ {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--spacer-200);
}

}
@layer ui {
  /* @define FormTemplate */

.Aside_jds-FormTemplate__aside__PtZzw {
  display: none;
}

.Aside_jds-FormTemplate__asideSticky__KjLEQ {
  position: sticky;
  top: var(--spacer-600);
}

/* stylelint-disable plugin/selector-bem-pattern */
.Aside_jds-FormTemplate__asideSticky__KjLEQ > *:first-child {
  margin-top: 0;
}
/* stylelint-enable plugin/selector-bem-pattern */

@media (min-width: 48rem) {
  .Aside_jds-FormTemplate__aside__PtZzw {
    display: flex;
    flex-direction: column;
  }
}

}
@layer ui {
  .InternalAnchorsItem_jds-InternalAnchorsItem__6d3_k {
  position: relative;
  width: 100%;
  list-style-type: none;
  font-size: var(--smallBody);
  font-weight: var(--semiBold);
  line-height: var(--smallBodyLineHeight);
}

.InternalAnchorsItem_jds-InternalAnchorsItem__6d3_k::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0.125rem; /* 2px */
  height: 100%;
}

.InternalAnchorsItem_jds-InternalAnchorsItem__6d3_k:focus-within:not(.InternalAnchorsItem_jds-InternalAnchorsItem--active__r4e28)::before,
.InternalAnchorsItem_jds-InternalAnchorsItem__6d3_k:hover::before {
  background-color: var(--color-grey--500);
}

.InternalAnchorsItem_jds-InternalAnchorsItem--active__r4e28:hover::before,
.InternalAnchorsItem_jds-InternalAnchorsItem--active__r4e28::before {
  background-color: var(--color-black);
}

.InternalAnchorsItem_jds-InternalAnchorsItem__anchor__MxPeD {
  display: block;
  padding: var(--spacer-150) var(--spacer-100) var(--spacer-150) var(--spacer-200);
  color: var(--color-grey--600);
  text-decoration: none;
}

.InternalAnchorsItem_jds-InternalAnchorsItem__anchor__MxPeD:focus-visible {
  outline: none;
}

.InternalAnchorsItem_jds-InternalAnchorsItem__anchor__MxPeD:hover,
.InternalAnchorsItem_jds-InternalAnchorsItem__anchor__MxPeD:focus-visible {
  color: var(--color-grey--600);
}

.InternalAnchorsItem_jds-InternalAnchorsItem--active__r4e28 .InternalAnchorsItem_jds-InternalAnchorsItem__anchor__MxPeD {
  color: var(--color-black);
}

}
@layer ui {
  .InternalAnchors_jds-InternalAnchors__VuK70 {
  position: relative;
  height: -moz-fit-content;
  height: fit-content;
}

.InternalAnchors_jds-InternalAnchors__VuK70::before {
  content: "";
  position: absolute;
  left: 0;
  width: 0.125rem; /* 2px */
  height: 100%;
  background-color: var(--color-grey--100);
}

.InternalAnchors_jds-InternalAnchors__list__Dx6Ic {
  margin: 0;
  padding: 0;
}

}
@layer ui {
  /* @define FormTemplate */

/* stylelint-disable plugin/selector-bem-pattern */
.Body_jds-FormTemplate__body__ll3td > *:first-child {
  margin-top: 0;
}
/* stylelint-enable plugin/selector-bem-pattern */

}
@layer ui {
  /* @define FormTemplate */

.FormTemplate_jds-FormTemplate__plEnK {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.FormTemplate_jds-FormTemplate__body__3ROVp {
  flex-grow: 1;
  padding-top: var(--spacer-600);
  padding-bottom: var(--spacer-600);
  background-color: var(--color-grey--025);
}

.FormTemplate_jds-FormTemplate__body--without-aside__ibkpX {
  display: flex;
  justify-content: center;
}

/* stylelint-disable plugin/selector-bem-pattern */
.FormTemplate_jds-FormTemplate__body--without-aside__ibkpX > * {
  max-width: 800px;
}

}
@layer ui {
  /* @define FormTemplate */

.Footer_jds-FormTemplate__footer__o9mUC {
  --FormTemplate-Footer-z-index: 10;

  position: sticky;
  z-index: var(--FormTemplate-Footer-z-index);
  bottom: 0;
  border-top: solid 1px var(--color-grey--100);
  background-color: var(--color-white);
}

.Footer_jds-FormTemplate__footerWrapper__4MkIa {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: var(--spacer-200);
  padding-bottom: var(--spacer-200);
  gap: var(--spacer-200);
}

}
@layer ui {
  .FooterSeparator_jds-FormTemplate__footerSeparator__1RZYU {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 1px;
  height: 1.5rem;
  margin: var(--spacer-150) var(--spacer-100);
  border: 0;
  background-color: var(--color-grey--100);
}

}
@layer ui {
  /* @define FormTemplate */

.Header_jds-FormTemplate__header___x2Tm {
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  min-height: 4rem; /* 64px */
  padding-top: var(--spacer-200);
  padding-bottom: var(--spacer-200);
  gap: var(--spacer-100);
  overflow-x: auto;
}

@media (min-width: 48rem) {
  .Header_jds-FormTemplate__header___x2Tm {
    flex-wrap: nowrap;
  }
}

}
@layer ui {
  /* @define ResponsiveButtons */

.ResponsiveButtons_jds-ResponsiveButtons__hideMobile__ML96T {
  display: none;
}

.ResponsiveButtons_jds-ResponsiveButtons__fluidMobileButton__hNrPf {
  width: 100%;
}

@media (min-width: 48rem) {
  /* stylelint-disable-next-line plugin/selector-bem-pattern */ /* needed to ensure CSS priority */
  [class*="jds-Dropdown"].ResponsiveButtons_jds-ResponsiveButtons__mobileOnly__MWTvd {
    display: none;
  }

  .ResponsiveButtons_jds-ResponsiveButtons__hideMobile__ML96T {
    display: block;
  }

  .ResponsiveButtons_jds-ResponsiveButtons__fluidMobileButton__hNrPf {
    width: -moz-max-content;
    width: max-content;
  }
}

}
@layer ui {
  /** @define Pictogram */
.Pictogram_jds-Pictogram__ZG8GF {
  fill: currentcolor;
  vertical-align: middle;
}

.Pictogram_jds-Pictogram--minor__wQY3C {
  width: var(--picto-size-minor); /* 36px */
  height: var(--picto-size-minor); /* 36px */
}

.Pictogram_jds-Pictogram--small__0SrfD {
  width: var(--picto-size-small); /* 48px */
  height: var(--picto-size-small); /* 48px */
}

.Pictogram_jds-Pictogram--medium__z2GTT {
  width: var(--picto-size-medium); /* 60px */
  height: var(--picto-size-medium); /* 60px */
}

.Pictogram_jds-Pictogram--large__TFbj1 {
  width: var(--picto-size-large); /* 96px */
  height: var(--picto-size-large); /* 96px */
}

.Pictogram_jds-Pictogram--major__PoFzE {
  width: var(--picto-size-major); /* 112px */
  height: var(--picto-size-major); /* 112px */
}

}
@layer ui {
  .arrow_jds-arrow__GdEXX {
  display: none;
  z-index: 1 !important;
}

@media (min-width: 48rem) {
  .arrow_jds-arrow__GdEXX {
    /* Get the same display as the button with only icon */
    display: inline-flex;
    position: absolute;
    align-self: center;
    transform: translate(-50%, 0);
    border: none;
  }

  .arrow_jds-arrow__GdEXX:focus {
    outline: none !important;
  }

  .arrow_jds-arrow--hidden__tlIYW {
    display: none;
  }
}

}
@layer ui {
  .content_jds-content__separator__bOxyb {
  margin: 0 var(--spacer-100);
  background-color: var(--color-grey--100);
}

@media (min-width: 48rem) {
  .content_jds-content__wrapper__9e0A_ {
    position: relative;
    overflow-y: visible;
  }

  .content_jds-content__separator__bOxyb {
    margin: 0 var(--spacer-150);
  }
}

}
@layer base {
  .Title_title__AcGZQ {
  margin: 0 0 var(--spacer-400);
}

@media (min-width: 48rem) {
  .Title_title__AcGZQ {
    margin-bottom: var(--spacer-700);
    font-size: var(--heading);
    line-height: var(--headingLineHeight);
  }

  .Title_title--small__tCfUd {
    margin-bottom: var(--spacer-600);
    font-size: var(--headingSmall);
    line-height: var(--headingSmallLineHeight);
  }
}

}
@layer ui {
  /**  @define FilterBar **/

.filterBar_jds-FilterBar___Fpze {
  display: flex;
  flex-direction: column;
  box-shadow: var(--default-shadow);
}

.filterBar_jds-FilterBar__element__dSN3u {
  flex: 1;
  min-width: 0;
  margin: 0;
  margin-right: -1px;
  margin-bottom: -1px;
}

.filterBar_jds-FilterBar__element__dSN3u:hover,
.filterBar_jds-FilterBar__element__dSN3u:focus-within {
  z-index: 1;
}

.filterBar_jds-FilterBar__element__dSN3u:not(:first-child, :last-child) .jds-Select__control {
  border-radius: 0;
}

.filterBar_jds-FilterBar__element__dSN3u:first-child .jds-Select__control,
.filterBar_jds-FilterBar__element__dSN3u:first-child > div > input {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.filterBar_jds-FilterBar__element__dSN3u:last-child .jds-Select__control {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@media (min-width: 48rem) {
  .filterBar_jds-FilterBar___Fpze {
    flex-direction: row;
  }

  .filterBar_jds-FilterBar__element__dSN3u:first-child .jds-Select__control,
  .filterBar_jds-FilterBar__element__dSN3u:first-child > div > input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: var(--radius-200);
  }

  .filterBar_jds-FilterBar__element__dSN3u:last-child .jds-Select__control {
    border-top-left-radius: 0;
    border-top-right-radius: var(--radius-200);
    border-bottom-left-radius: 0;
  }
}

}
@layer ui {
  /** @define Paper */

.paper_jds-Paper__W_bcw {
  padding: var(--spacer-300);
  background-color: var(--color-white);
  box-shadow: var(--default-shadow);
}

.paper_jds-Paper--isDark__BtGLj {
  background-color: var(--color-black);
  color: var(--color-white);
}

.paper_jds-Paper--noSpacing__5fBkQ {
  padding: 0;
}

.paper_jds-Paper--noShadow__6sUru {
  box-shadow: none;
}

.paper_jds-Paper--noBackground__9kbgQ {
  background-color: transparent;
}

.paper_jds-Paper--radius__qzXAo {
  border-radius: var(--radius-200);
}

.paper_jds-Paper--radiusRounded__GhgtS {
  border-radius: var(--radius-900);
}

.paper_jds-Paper--isFullWidth__pe_8P {
  padding-right: 0;
  padding-left: 0;
}

.paper_jds-Paper--hasBorder__DxYyT {
  border: var(--border);
}

@media (min-width: 48rem) {
  .paper_jds-Paper__W_bcw:not(.paper_jds-Paper--smallSpacing__GJYWV, .paper_jds-Paper--isFullWidth__pe_8P, .paper_jds-Paper--noSpacing__5fBkQ) {
    padding: var(--spacer-400) var(--spacer-600);
  }
}

}
@layer ui {
  /* @define FormTemplate */

.Section_jds-FormTemplate__section__fTP0Z {
  margin-top: var(--spacer-300);
}

.Section_jds-FormTemplate__section--withTitle__JaQ04 {
  margin-top: var(--spacer-600);
}

.Section_jds-FormTemplate__section--withTitle__JaQ04:first-of-type {
  margin-top: 0;
}

.Section_jds-FormTemplate__paperSection___1G5q {
  /* Override default Paper component padding. */
  padding: var(--spacer-400) !important;
}

/* stylelint-disable plugin/selector-bem-pattern */
.Section_jds-FormTemplate__paperSection___1G5q > *:last-child {
  margin-bottom: 0;
}

/* stylelint-enable plugin/selector-bem-pattern */

}
@layer base {
  .NewLanguage_container__c_93_ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacer-300);
  padding: var(--spacer-500);
  border: solid 1px var(--color-grey--100);
  background-color: var(--color-white);
}

.NewLanguage_buttonsContainer__oW0BS {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: var(--spacer-200);
}

@media (min-width: 48rem) {
  .NewLanguage_container__c_93_ {
    padding: var(--spacer-1000);
  }

  .NewLanguage_heading__agdLl {
    font-size: var(--headingSmall);
    line-height: var(--headingSmallLineHeight);
  }
}

}
@layer ui {
  .header_jds-header--hidden__e3698 {
  opacity: 0;
}

}
@layer ui {
  .item_jds-item--hidden__QPhMf {
  opacity: 0;
}

}
@layer ui {
  /** @define Status */

.status_jds-Status__I8m97 {
  display: inline-flex;
  align-items: center;
}

.status_jds-Status__I8m97::before {
  content: "";
  display: inline-block;
  flex-shrink: 0;
  width: var(--icon-size-tiny);
  height: var(--icon-size-tiny);
  margin-right: var(--spacer-100);
  border-radius: var(--radius-900);
  vertical-align: middle;
}

.status_jds-Status--active__nZ2DW::before {
  background-color: var(--color-success--500);
}

.status_jds-Status--inactive__yIA4M::before {
  background-color: var(--color-error--500);
}

.status_jds-Status--warning__e_U_q::before {
  background-color: var(--color-warning--500);
}

.status_jds-Status--neutral__wkb7R::before {
  background-color: var(--color-grey--200);
}

}
@layer base {
  .PagesStatus_carousel__ZQbjL {
  box-sizing: border-box;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
  border: var(--border);
  border-radius: var(--radius-100);
}

.PagesStatus_item__WoPIe {
  position: relative;
  width: -moz-max-content;
  width: max-content;
  padding: var(--spacer-200) var(--spacer-300);
  overflow: hidden;
  border: none;
  background: transparent;
  font-family: var(--fontFamily);
  text-align: inherit;
  cursor: pointer;
}

/* setting borders on the first and last items prevents the hover pseudo-element to overflow the card */
/* we can't set an overflow: hidden on the Carousel because it would hide the Carousel arrows */

.PagesStatus_item__first__XG5X3 {
  border-radius: var(--radius-100) 0 0 var(--radius-100);
}

.PagesStatus_item__last__IQnUC {
  border-radius: 0 var(--radius-100) var(--radius-100) 0;
}

.PagesStatus_item__WoPIe:hover::after,
.PagesStatus_item__active__4ThSu::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 4px;
}

.PagesStatus_item__WoPIe:hover::after {
  background: var(--color-grey--200);
}

.PagesStatus_item__active__4ThSu::after,
.PagesStatus_item__active__4ThSu:hover::after {
  background: var(--color-black);
}

.PagesStatus_separator__nWorZ {
  flex-shrink: 0;
  width: 1px;
  margin: var(--spacer-200) 0;
  background-color: var(--color-grey--100);
}

.PagesStatus_date__d_EsK {
  color: var(--color-grey--600);
}

}
@layer ui {
  /* @define Note */

.Note_jds-Note__SyY4K {
  display: flex;
  align-items: center;
  padding: var(--spacer-200) var(--spacer-200) var(--spacer-200) var(--spacer-300);
  gap: var(--spacer-200);
  border-radius: var(--radius-200);
}

.Note_jds-Note--loading__OPTYk {
  background-color: var(--color-grey--025) !important;
}

.Note_jds-Note--primary__tnFZB {
  background-color: var(--color-purple--100);
}

.Note_jds-Note--neutral__IOVI2 {
  background-color: var(--color-grey--050);
}

.Note_jds-Note--success__WSt9G {
  background-color: var(--color-success--100);
}

.Note_jds-Note--warning__hPqbt {
  background-color: var(--color-warning--100);
}

.Note_jds-Note--error__TXQcq {
  background-color: var(--color-error--100);
}

.Note_jds-Note__icon--success__v8HMq {
  color: var(--color-black);
}

.Note_jds-Note__icon--warning__8FwVO {
  color: var(--color-warning--500);
}

.Note_jds-Note__icon--error__Gx5Lw {
  color: var(--color-error--500);
}

.Note_jds-Note__body__TbCQO {
  flex: 1;
}

.Note_jds-Note__bodyTitle__PCf77 {
  font-weight: var(--semiBold);
}

.Note_jds-Note__closeButton__vLWj5 {
  align-self: flex-start;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}

}
@layer ui {
  /** @define ReadMore */
.Readmore_jds-ReadMore__truncatedText__TrnCV {
  /* https://css-tricks.com/almanac/properties/l/line-clamp/ */
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--lineNumber);
  line-clamp: var(--lineNumber);
  overflow: hidden;
  text-overflow: ellipsis;
}

.Readmore_jds-ReadMore__truncatedText--max-height__Wujlz {
  display: block;
  max-height: calc(var(--lineNumber) * 1.8em);
}

.Readmore_jds-ReadMore__truncatedText--expanded__kOY4x {
  max-height: none;
}

.Readmore_jds-ReadMore__link__ETWRT {
  display: inline-block;
  margin-top: var(--spacer-100);
  font-weight: var(--bold);
  cursor: pointer;
}

}
@layer base {
  .PersonalIdentifiableInformationBlock_title__KBp2r {
  margin-top: var(--sk-space-24);
}

.PersonalIdentifiableInformationBlock_content__Ld2nn {
  color: var(--sk-color-grey-600);
  font-size: var(--sk-typography-body-2-regular-font-size);
  line-height: var(--sk-typography-body-2-regular-line-height);
}

}
@layer base {
  .ProfileBlock_title__n_qOJ {
  margin: var(--sk-space-8) 0;
}

.ProfileBlock_courseList__DkNO5 {
  margin: var(--sk-space-16) 0 0;
  padding: 0;
  list-style-type: none;
  color: var(--sk-color-grey-700);
}

.ProfileBlock_schoolName__Uv2Fk + .ProfileBlock_presentation__HDMM8 {
  margin-top: var(--sk-space-16);
}

.ProfileBlock_courseList__DkNO5 + .ProfileBlock_presentation__HDMM8 {
  margin-top: var(--sk-space-4);
}

}
@layer base {
  .SubscriptionModal_section__V86_f {
  position: relative;
  margin-top: var(--sk-space-8);
  padding: var(--sk-space-24);
  border: 1px solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-4);
}

.SubscriptionModal_editButton__q9V3E {
  margin: 0 0 var(--sk-space-8) var(--sk-space-8);
  float: right;
}

.SubscriptionModal_instructionsContent__cCGpZ {
  white-space: pre-wrap;
}

.SubscriptionModal_instructionNote___J1pk {
  margin-bottom: var(--sk-space-24);
}

.SubscriptionModal_manualNote__5mLJn {
  margin-top: var(--sk-space-24);
}

/* necessary for the skeleton to be exactly the desired height */
/* https://github.com/dvtng/react-loading-skeleton/issues/23#issuecomment-939231878 */
.SubscriptionModal_profileBlockPlaceholder__3yt_G {
  display: block;
  line-height: 1;
}

}
@layer ui {
  /** @define RadioButton */
.radioButton_jds-RadioButton__S8dNK {
  --RadioButton-diameter: 1.25rem; /* 20px */
  display: inline-flex;
  align-items: center;
  color: var(--color-black);
  font-size: var(--normalBody);
  line-height: var(--normalBodyLineHeight);
  cursor: pointer;
}

.radioButton_jds-RadioButton__input__3wDSo {
  box-sizing: border-box;
  flex-shrink: 0;
  width: var(--RadioButton-diameter);
  height: var(--RadioButton-diameter);
  margin: 0;
  border: 1px solid var(--color-grey--200);
  border-radius: var(--radius-900);
  outline: 0;
  background-color: var(--color-white);
  vertical-align: text-bottom;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.radioButton_jds-RadioButton__input--withLabel__t6GcE {
  margin-right: var(--spacer-100);
}

/* "hover" and "focus" states */

.radioButton_jds-RadioButton__input__3wDSo:not(:disabled):hover,
.radioButton_jds-RadioButton__input__3wDSo:not(:disabled):focus {
  outline: 2px solid var(--color-grey--500);
  outline-offset: -1px;
}

/* "checked" state */

.radioButton_jds-RadioButton__input__3wDSo:checked {
  position: relative;
  border-color: var(--themeMainColor, currentcolor);
  color: var(--color-black);
}

.radioButton_jds-RadioButton__input__3wDSo:checked::after {
  content: "";
  position: absolute;
  top: calc(50% - var(--RadioButton-diameter) / 4);
  left: calc(50% - var(--RadioButton-diameter) / 4);
  width: calc(var(--RadioButton-diameter) / 2);
  height: calc(var(--RadioButton-diameter) / 2);
  border-radius: var(--radius-900);
  background-color: var(--themeMainColor, currentcolor);
}

/* "disabled" state */

.radioButton_jds-RadioButton--isDisabled__W7Ix0 {
  color: var(--color-grey--200);
  cursor: default;
}

.radioButton_jds-RadioButton__input__3wDSo:disabled,
.radioButton_jds-RadioButton__input__3wDSo:disabled:checked::after {
  border-color: var(--color-grey--200);
  background-color: var(--color-grey--100);
  cursor: default;
}

.radioButton_jds-RadioButton__input__3wDSo:disabled:checked::after {
  background-color: var(--color-grey--200);
}

}
@layer ui {
  /** @define Input */

.input_jds-Input__x_yHZ {
  --Input-iconSize: 1.25rem;

  display: flex;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  min-height: var(--field-height);
  padding: 0 var(--spacer-200);
  border: thin solid var(--color-grey--200);
  border-radius: var(--radius-200);
  color: var(--color-black);
  font-family: inherit;
  font-size: var(--normalBody);
  font-weight: var(--normal);
  line-height: var(--normalBodyLineHeight);
}

.input_jds-Input--rightIcon__Hcje3 {
  padding-right: calc(var(--Input-iconSize) + var(--spacer-200) + var(--spacer-150));
}

.input_jds-Input--leftIcon__BsHKd {
  padding-left: calc(var(--Input-iconSize) + var(--spacer-200) + var(--spacer-150));
}

.input_jds-Input__x_yHZ:disabled {
  border-color: var(--color-grey--200);
  background-color: var(--color-grey--025);
  color: var(--color-grey--200);
}

.input_jds-Input__x_yHZ::-moz-placeholder {
  opacity: 1;
  color: var(--color-grey--500);
}

.input_jds-Input__x_yHZ::placeholder {
  opacity: 1;
  color: var(--color-grey--500);
}

.input_jds-Input__x_yHZ:disabled::-moz-placeholder {
  color: var(--color-grey--200);
}

.input_jds-Input__x_yHZ:disabled::placeholder {
  color: var(--color-grey--200);
}

.input_jds-Input__x_yHZ:not(:disabled):hover,
.input_jds-Input__x_yHZ:not(:disabled):focus {
  outline: 2px solid var(--color-grey--500);
  outline-offset: -1px;
}

.input_jds-Input--hasError__L_Tsh {
  border-color: var(--color-error--500);
}

.input_jds-Input--hasError__L_Tsh:not(:disabled):hover,
.input_jds-Input--hasError__L_Tsh:not(:disabled):focus {
  outline-color: var(--color-error--500);
}

}
@layer ui {
  /** @define Textarea */

.textarea_jds-Textarea__3gkpv {
  box-sizing: border-box;
  width: 100%;
  padding: var(--spacer-150) var(--spacer-200);
  border: 1px solid var(--color-grey--200);
  border-radius: var(--radius-200);
  font-family: var(--fontFamily);
  font-size: var(--normalBody);
  font-weight: var(--normal);
  line-height: var(--normalBodyLineHeight);
}

.textarea_jds-Textarea--noResize__urVcx {
  resize: none;
}

.textarea_jds-Textarea__3gkpv::-moz-placeholder {
  color: var(--color-grey--200);
}

.textarea_jds-Textarea__3gkpv::placeholder {
  color: var(--color-grey--200);
}

.textarea_jds-Textarea__3gkpv:not(:disabled):hover,
.textarea_jds-Textarea__3gkpv:not(:disabled):focus {
  outline: 2px solid var(--color-grey--500);
  outline-offset: -1px;
}

.textarea_jds-Textarea__3gkpv:disabled {
  border-color: var(--color-grey--200);
  background-color: var(--color-grey--025);
  color: var(--color-grey--200);
}

.textarea_jds-Textarea__3gkpv:disabled::-moz-placeholder {
  color: var(--color-grey--200);
}

.textarea_jds-Textarea__3gkpv:disabled::placeholder {
  color: var(--color-grey--200);
}

.textarea_jds-Textarea--hasError__HlHV9 {
  border-color: var(--color-error--500);
}

.textarea_jds-Textarea--hasError__HlHV9:not(:disabled):hover,
.textarea_jds-Textarea--hasError__HlHV9:not(:disabled):focus {
  outline-color: var(--color-error--500);
}

.textarea_jds-Textarea__switchWrapper__PIKC3 {
  display: flex;
}

.textarea_jds-Textarea__counter__d_KwE {
  flex-shrink: 0;
  margin-left: auto;
  padding-top: var(--spacer-50);
  padding-left: var(--spacer-200);
  color: var(--color-grey--600);
}

.textarea_jds-Textarea__counter--error__TZ6bi {
  color: var(--color-error--600);
}

}
@layer base {
  .Services_serviceField__lKVUl {
  flex: 1 0 auto;
}

.Services_shortButtons__PbfPo {
  margin-bottom: var(--sk-space-16);
}
}
@layer base {
  .Teams_uploadFile__5aPQt {
  margin-bottom: var(--spacer-300);
}

.Teams_member__dSkxe {
  margin-top: var(--sk-space-32);
  padding-top: var(--sk-space-32);
  border-top: 1px solid var(--sk-color-grey-100);
}


.Teams_memberList__P_6Rv {
  padding: 0;
  list-style-type: none;
}

}
@layer base {
  .MenuDropdown_dropdown__iM4YR {
  width: 100%;
}

.MenuDropdown_triggerButton__YJCKf {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 var(--spacer-100);
  border-color: transparent;
  background-color: transparent;
  color: black;
  gap: var(--spacer-100);
  font-size: var(--normalBody);
  font-weight: var(--normal);
  text-align: left;
}

.MenuDropdown_triggerButton__selected__MR7xG {
  background-color: var(--color-black);
  color: var(--color-white);
}

.MenuDropdown_triggerButton__YJCKf:hover {
  background-color: var(--color-grey--100);
}

/* reset CSS from Dropdown's Button component */
.MenuDropdown_triggerButton__YJCKf:hover::before {
  background-color: transparent;
}

.MenuDropdown_triggerButton__YJCKf:focus {
  border-color: var(--color-black);
  background-color: transparent;
}

.MenuDropdown_triggerButton__selected__MR7xG:hover {
  background-color: var(--color-grey--800);
}

.MenuDropdown_triggerButton__selected__MR7xG:focus {
  background-color: var(--color-black);
}

.MenuDropdown_triggerButtonInitial__Nenpk {
  width: var(--spacer-300);
  height: var(--spacer-300);
  border-radius: var(--radius-900);
  background-color: var(--color-grey--100);
  color: var(--color-black) !important;
  font-size: 10px;
  text-align: center;
}

.MenuDropdown_triggerButton__YJCKf:not(.MenuDropdown_triggerButton__selected__MR7xG):hover .MenuDropdown_triggerButtonInitial__Nenpk {
  background-color: var(--color-grey--200);
}

.MenuDropdown_triggerButtonText__ICZyM {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.MenuDropdown_triggerButtonArrowWithTag__JsOD8 {
  display: flex;
  align-items: center;
  gap: var(--spacer-25);
}

.MenuDropdown_triggerButtonText___hidden__BxAoZ {
  display: none;
}

.MenuDropdown_dropdownUserMenuItemLanguage__9Sune {
  display: flex;
  justify-content: space-between;
  border-bottom: thin solid var(--color-grey--100);
}

.MenuDropdown_dropdownUserMenuItemCurrentLocale__ER_l2 {
  text-transform: uppercase;
}

.MenuDropdown_dropdownConsumptionMenuItem__5FgLt {
  display: flex;
  justify-content: space-between;
  gap: var(--spacer-200);
  pointer-events: none;
}

.MenuDropdown_dropdownConsumptionMenuItemLink__j_YkA {
  border-top: thin solid var(--color-grey--100);
}

.MenuDropdown_dropdownConsumptionMenuItemValue___FOWd {
  font-weight: var(--semiBold);
}

.MenuDropdown_newTag__4GObg {
  margin-left: var(--spacer-200);
}

}
@layer base {
  /* stylelint-disable max-line-length */

/**
 * UI-Kit uses react-select and this one uses the Emotion CSS-in-JS library to style its components.
 * CSS-in-JS libraries are not compatible with our @layer strategy of CSS loading.
 * That's why we have to use !important on nextjs context.
 */

.Select_select__qM0Ez [class*="jds-Select__control"] {
  border-radius: var(--radius-200) !important;
  cursor: pointer !important;
}

.Select_select__qM0Ez [class*="jds-Select__dropdown-indicator"] {
  padding-right: var(--spacer-200) !important;
  padding-left: calc(var(--spacer-150) / 2) !important;
  color: var(--color-black) !important;
}

.Select_select__qM0Ez [class*="jds-Select__option"] {
  padding: var(--spacer-150) var(--spacer-200) !important;
}

.Select_select__qM0Ez [class*="jds-GroupedCheckbox"] [class*="jds-Select__option"] {
  padding-left: calc(var(--spacer-200) * 2) !important;
}

.Select_select__qM0Ez [class*="jds-Select__option--is-selected"] {
  background-color: var(--color-white) !important;
  color: var(--color-black) !important;
}

.Select_select__qM0Ez [class*="jds-Select__option--is-selected"] [class*="jds-Option__TickBox"] {
  color: var(--color-white) !important;
}

.Select_select__qM0Ez [class*="jds-Select__option--is-focused"],
.Select_select__qM0Ez [class*="jds-Select__option:hover"],
.Select_select__qM0Ez [class*="jds-Select__option:focus"] {
  background-color: var(--color-grey--025) !important;
}

.Select_select__qM0Ez[class*="jds-Select--hasError"] .jds-Select__control {
  border-color: var(--color-error--500) !important;
}

.Select_select__qM0Ez [class*="jds-Select__input-container"] {
  margin: 0 !important;
}

.Select_select__qM0Ez [class*="jds-Select__multi-value"] {
  border-radius: var(--radius-200) !important;
  cursor: pointer !important;
}

.Select_select__qM0Ez [class*="jds-Select__placeholder"],
.Select_select__qM0Ez [class*="jds-Select__single-value"],
.Select_select__qM0Ez [class*="jds-Select__input"] {
  margin: 0 !important;
}

.Select_select__qM0Ez
  [class*="jds-Select__control--is-focused jds-Select__control--menu-is-open"]
  [class*="jds-Select__single-value"] {
  color: var(--color-grey--500) !important;
}

.Select_select__qM0Ez [class*="jds-Select__value-container"] {
  padding: 0 0 0 var(--spacer-200) !important;
}

.Select_select__qM0Ez [class*="jds-Select__value-container--is-multi"] {
  max-height: 82px !important;
  overflow: auto !important;
}

.Select_select__qM0Ez [class*="jds-Select__value-container--is-multi jds-Select__value-container--has-value"] {
  padding: var(--spacer-100) 0 var(--spacer-100) var(--spacer-200) !important;
}

.Select_select__qM0Ez [class*="jds-Select__multi-value__remove"] {
  background-color: inherit !important;
  color: inherit !important;
  cursor: pointer !important;
}

.Select_select__qM0Ez .jds-Select__menu {
  box-shadow: var(--default-shadow) !important;
}

.Select_select__qM0Ez [class*="jds-Select__menu-list"] {
  max-height: 264px !important;
  padding: 0 !important;
  border-bottom: 1px solid var(--color-grey--100) !important;
}

[data-theme="jobteaser-uishift"] .Select_select__qM0Ez .jds-Select__menu {
  border-radius: var(--radius-400) !important;
}

[data-theme="jobteaser-uishift"] .Select_select__qM0Ez .jds-Select__indicators {
  height: var(--Select-innerHeight);
}

[data-theme="jobteaser-uishift"] .Select_select__qM0Ez [class*="IndicatorContainer_jds-IndicatorContainer__tag"] {
  margin-right: var(--spacer-200);
  padding: 0 var(--spacer-50) 0 var(--spacer-100);
  background-color: var(--color-grey--100);
  color: var(--color-black);
}

[data-theme="jobteaser-uishift"] .Select_select__qM0Ez .jds-Select__multi-value {
  border-radius: var(--radius-200) !important;
  background-color: var(--color-black) !important;
  color: var(--color-white) !important;
}

[data-theme="jobteaser-uishift"] .Select_select__qM0Ez .jds-Select__multi-value__label {
  color: var(--color-white) !important;
}

@media (min-width: 48rem) {
  [data-theme="jobteaser-uishift"]
    .Select_select__qM0Ez
    [class*="IndicatorContainer_jds-IndicatorContainer__tag"]
    > div[role="button"] {
    display: none;
  }

  [data-theme="jobteaser-uishift"] .Select_select__qM0Ez [class*="IndicatorContainer_jds-IndicatorContainer__tag"] {
    padding: 0 var(--spacer-100) 0 var(--spacer-100);
  }

  [data-theme="jobteaser-uishift"] .Select_select__qM0Ez [class*="IndicatorContainer_jds-IndicatorContainer__tag"]:hover {
    padding: 0 var(--spacer-50) 0 var(--spacer-150);
  }

  [data-theme="jobteaser-uishift"]
    .Select_select__qM0Ez
    [class*="IndicatorContainer_jds-IndicatorContainer__tag"]:hover
    > div[role="button"] {
    display: inherit;
  }
}

}
@layer base {
  .QuestionBlock_title___g_2l {
  margin: var(--sk-space-24) 0 var(--sk-space-16);
}

}
@layer base {
  .RadioRemote_option__LIM77 {
  display: block;
}

.RadioRemote_option__LIM77:not(:last-child) {
  margin-bottom: var(--spacer-200);
}

.RadioRemote_radioGroup__jbjqF {
  margin-bottom: var(--spacer-150);
}

.RadioRemote_field__f9oPf {
  margin-bottom: var(--spacer-400);
}

.RadioRemote_title__uHNw_ {
  margin-bottom: var(--spacer-200);
}

}
@layer base {
  .LanguageModal_main__DNIhx {
  overflow-y: visible;
}

.LanguageModal_selectField__Dvx_x {
  /* Use z-index to display the select options list in front of the modal buttons */
  z-index: var(--z-above-ui-kit-buttons);
}

}
@layer base {
  .LegalModal_links__ruSJk {
  display: flex;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 10px;
  /* Override BO londinium CSS */
  padding-left: 0;
  list-style-type: none;
  gap: var(--spacer-100);
  font-size: var(--smallBody);
}

}
@layer base {
  .MenuItem_link__HSrUN {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 var(--spacer-100);
  border-radius: var(--radius-100);
  font-size: var(--normalBody);
  text-decoration: none;
  white-space: nowrap;
  gap: var(--spacer-100);
}

.MenuItem_link___unselected__R_QQs {
  color: var(--color-black);
}

.MenuItem_link___selected__wZrm8 {
  background-color: var(--color-black);
  color: var(--color-white) !important;
}

.MenuItem_link___collapsed__WpqKt {
  justify-content: center;
}

.MenuItem_link__HSrUN:hover {
  background-color: var(--color-grey--100);
  text-decoration: none;
}

.MenuItem_link___selected__wZrm8:hover {
  background-color: var(--color-grey--800);
}

.MenuItem_link__HSrUN:focus {
  box-sizing: border-box;
  padding: 0 calc(var(--spacer-100) - 1px);
  border: thin solid var(--color-black);
  outline: none;
}

.MenuItem_linkText__mGy1M {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.MenuItem_linkText___hidden__Ctmip {
  display: none;
}

}
@layer base {
  /* stylelint-disable plugin/selector-bem-pattern */
/** @define Loading */

.Loading_sha-Loading__tmLTe {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: auto;
}

.Loading_sha-Loading__spinner--isOnWhiteBackground__pHKQ_ {
  stop-color: var(--mainColor);
}

}
@layer base {
  .Drawer_main__kqud4 {
  display: flex;
  position: fixed;
  z-index: var(--z-index-website-menu);
  box-sizing: border-box;
  flex-direction: column;
  width: var(--jt-bo-drawer-width);
  /* using svh instead of vh (when available) to avoid the drawer being too long on mobile devices */
  height: calc(100vh - var(--notification-banner-height));
  height: calc(100svh - var(--notification-banner-height));
  padding: 0 var(--spacer-100);
  background-color: var(--color-grey--025);
  box-shadow: 0 0 4px var(--color-grey--100);
}

.Drawer_main__collapsed__xsTvP {
  position: sticky;
  top: var(--notification-banner-height);
  width: var(--jt-bo-drawer-collapsed-width);
}

.Drawer_main__loading__u08fi {
  margin: 0;
}

.Drawer_backdrop__sokMw {
  position: fixed;
  /*
    Below the drawer but above the rest of the page.
    (It is below the drawer with the same z-index because it is before in order of appearance in the HTML.)
  */
  z-index: var(--z-index-website-menu);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background-color: var(--color-black);
}

.Drawer_spacer__Kke79 {
  width: var(--jt-bo-drawer-collapsed-width);
}

.Drawer_header__zltHJ {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacer-400);
  padding: var(--spacer-200) var(--spacer-150);
  transition: none;
  border-bottom: thin solid var(--color-grey--100);
  text-decoration: none;
  gap: var(--spacer-150);
}

.Drawer_headerLink__6VkMR,
.Drawer_headerLink__6VkMR:hover {
  display: flex;
  gap: var(--spacer-150);
  align-items: center;
  text-decoration: none;
}

.Drawer_header__collapsed__drQvf {
  justify-content: center;
  padding-right: 0;
  padding-left: 0;
}

.Drawer_header__zltHJ:hover {
  text-decoration: none;
}

.Drawer_title__EYavJ {
  flex: 1;
}

.Drawer_collapse__lzPod {
  padding: 0 !important;
  border: none;
  stroke: var(--color-grey--500);
}

.Drawer_collapse__lzPod:hover::before {
  background: none;
}

.Drawer_logo__DLciR {
  /* ensure the link keeps the same size as the image */
  display: block;
  width: 40px;
  height: 40px;
  border-radius: var(--radius-100);
  background-color: var(--color--grey-50);
}

.Drawer_menu__ta8GM {
  display: flex;
  flex-direction: column;
  min-height: 0; /* it allows the flex container to shrink below the height of its content if necessary */
  border-bottom: thin solid var(--color-grey--100);
}

.Drawer_menuItemsList__lyYP6 {
  margin-top: 0;
  /* Override BO londinium CSS */
  margin-bottom: 0;
  /* Override BO londinium CSS */
  padding-left: 0;
  /*
  The scrollable area is only on the menu items list and not on the whole menu,
  because the overflow-y auto or scroll is not compatible with the presence of dropdowns.
  It would create horizontal scrolling that we can't hide without making the dropdowns unusable.
  In CSS, if you are using visible for either overflow-x or overflow-y and something other than visible for the other,
  the visible value is interpreted as auto.
   */
  overflow-y: auto;
  list-style-type: none;
}

.Drawer_dropdowns__D9ciY {
  flex: 1;
}

.Drawer_helpCenter__cBTML {
  margin-top: var(--spacer-150);
  margin-bottom: var(--spacer-200);
}

@media (min-width: 48rem) {
  .Drawer_main__kqud4 {
    position: sticky;
    top: var(--notification-banner-height);
  }
}

}
