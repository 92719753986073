@layer base {
  .title {
  margin-top: var(--sk-space-24);
}

.content {
  color: var(--sk-color-grey-600);
  font-size: var(--sk-typography-body-2-regular-font-size);
  line-height: var(--sk-typography-body-2-regular-line-height);
}

}