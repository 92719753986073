@layer base {
  .title {
  margin: var(--sk-space-8) 0;
}

.courseList {
  margin: var(--sk-space-16) 0 0;
  padding: 0;
  list-style-type: none;
  color: var(--sk-color-grey-700);
}

.schoolName + .presentation {
  margin-top: var(--sk-space-16);
}

.courseList + .presentation {
  margin-top: var(--sk-space-4);
}

}