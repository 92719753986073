@layer base {
  .container a[class*="jds-Pagination__activeButton"] {
  background: var(--color-black) !important;
  color: var(--color-white) !important;
}

.filters {
  position: relative;
  z-index: var(--z-above-ui-kit-cards); /* to override some z-index inside Cards, below the FilterBar */
}

.grid {
  display: grid;
  grid-auto-rows: minmax(100px, auto);
  gap: var(--spacer-200);
  grid-template-columns: repeat(1, 1fr);
  margin-top: var(--spacer-400);
}

.image {
  border-radius: var(--radius-200);
  object-fit: contain;
}

@media (--large-viewport) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (--huge-viewport) {
  .grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.grid__withError {
  grid-template-columns: none;
}

}