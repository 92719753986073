@layer base {
  .section {
  position: relative;
  margin-top: var(--sk-space-8);
  padding: var(--sk-space-24);
  border: 1px solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-4);
}

.editButton {
  margin: 0 0 var(--sk-space-8) var(--sk-space-8);
  float: right;
}

.instructionsContent {
  white-space: pre-wrap;
}

.instructionNote {
  margin-bottom: var(--sk-space-24);
}

.manualNote {
  margin-top: var(--sk-space-24);
}

/* necessary for the skeleton to be exactly the desired height */
/* https://github.com/dvtng/react-loading-skeleton/issues/23#issuecomment-939231878 */
.profileBlockPlaceholder {
  display: block;
  line-height: 1;
}

}